<template>
  <v-form ref="form" v-model="valid">
    <v-dialog v-model="dialog" width="750" persistent>

      <v-card>
        <v-toolbar color="success" dark flat>
          <span class="headline">Funcionário</span>
          <v-spacer />
          <v-btn flat icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container grid-list-md>
          <v-card-text>
            <v-layout wrap>
              <v-flex xs6>
                <v-text-field
                  v-model="employee.cpf"
                  label="CPF"
                  :rules="[validationRules.required, validationRules.cpf]"
                  :mask="masks.cpf"
                  @blur="checkUser()"
                  :readonly="employee.id === null"
                  :disabled="employee.id === null"
                />
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="employee.name"
                  label="Nome Completo"
                  :rules="[validationRules.required]"
                  :readonly="isReadonly"
                  :disabled="isReadonly"
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="employee.email"
                  label="E-mail"
                  :rules="[validationRules.required, validationRules.email]"
                  :readonly="isReadonly"
                  :disabled="isReadonly"
                />
              </v-flex>
              <v-flex xs6>
                <v-select
                  v-model="employee.roles"
                  label="Perfil"
                  :items="roles.slice(1, 4)"
                  item-text="name"
                  item-value="key"
                  :rules="[validationRules.required]"
                />
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn flat color="error" @click="close">Cancelar</v-btn>
            <v-btn
              v-if="employee.id === '' && id !== null"
              color="success"
              :disabled="!valid"
              @click="update"
            >
              Atualizar
            </v-btn>
            <v-btn
              v-if="employee.id === '' && id === null"
              color="success"
              :disabled="!valid"
              @click="create"
            >
              Salvar
            </v-btn>
            <v-btn
              color="success"
              v-if="employee.id !== ''"
              :disabled="!valid"
              @click="link"
            >
              Vincular
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>

import personService from '@/services/person-service';
import validationRules from '@/mixins/validation-rules';
import userService from '@/services/user-service';
import masks from '@/utils/masks';
import employeeService from '@/services/employee-service';
// the 'ID' property on data is peopleUser.id. It will be used when update de roles field from user
export default {
  name: 'CreateOrUpdateEmployeeDialog',
  props: ['dialog', 'editedItem'],
  data() {
    return {
      roles: [],
      employee: {
        id: '',
        name: '',
        email: '',
        cpf: '',
        roles: '',
      },
      id: null,
      masks,
      valid: false,
      validationRules,
      isReadonly: false,
    };
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    async checkUser() {
      if (this.employee.cpf !== '') {
        personService
          .checkUserAlreadyExist(this.employee.cpf)
          .then(({ data }) => {
            this.isReadonly = true;
            this.employee = {
              ...this.employee,
              id: data.id,
              name: data.name,
              email: data.email,
            };
          })
          .catch(() => {
            this.isReadonly = false;
            this.employee = {
              id: '',
              cpf: this.employee.cpf,
              person_id: this.employee.person_id,
            };
          });
      }
    },
    async getRoles() {
      this.roles = await userService.getRoles();
    },
    close() {
      this.$refs.form.reset();
      this.$emit('close');
    },
    link() {
      const payload = {
        user_id: this.employee.id,
        person_id: this.person.id,
        role: this.employee.roles,
      };

      employeeService.linkEmployee(payload)
        .then(() => {
          this.$store.dispatch('person/fetchEmployees', this.person.id);
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'success',
            message: 'Colaborador vinculado com sucesso.',
            messageIcon: 'mdi-ckeck',
          });
          this.close();
        })
        .catch(({ response }) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: response.data.join(', '),
            messageIcon: 'mdi-alert-outline',
          });
        });
    },
    create() {
      const payload = { ...this.employee, person_id: this.person.id }
      userService.createEmployee(payload)
        .then(() => {
          this.$store.dispatch('person/fetchEmployees', this.person.id);
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'success',
            message: 'Colaborador criado com sucesso.',
            messageIcon: 'mdi-ckeck',
          });
          this.close();
        })
        .catch(({ response }) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: response.data.join(', '),
            messageIcon: 'mdi-alert-outline',
          });
        });
    },
    update() {
      const payload = {
        id: this.id,
        roles: [this.employee.roles],
      };
      userService
        .updateEmployee(payload)
        .then(() => {
          this.$store.dispatch('person/fetchEmployees', this.person.id);
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'success',
            message: 'Colaborador atualizado com sucesso.',
            messageIcon: 'mdi-ckeck',
          });
          this.close();
        })
        .catch(() => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: 'Erro ao atualizar colaborador',
            messageIcon: 'mdi-alert-outline',
          });
        });
    },
  },
  computed: {
    person() {
      return this.$store.getters['person/person'];
    },
  },
  watch: {
    editedItem(newValue) {
      if (newValue) {
        this.id = newValue.id
        this.employee.name = newValue.user.name;
        this.employee.email = newValue.user.email;
        this.employee.cpf = newValue.user.cpf;
        [this.employee.roles] = newValue.roles;
        this.isReadonly = true;
      } else {
        this.id = null;
        this.isReadonly = false;
      }
    },
  },
};
</script>
